import {BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom";
import {I18nextProvider} from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // Your website's dark mode is enabled, enforce light mode styles here
    document.documentElement.style.setProperty('--background-color', '#000000');
    // Continue setting properties as needed
}

const App = () => (
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <Router/>
            </I18nextProvider>
        </BrowserRouter>
    )
;

ReactDOM.render(<App/>, document.getElementById("root"));
