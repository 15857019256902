import { Row } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import {
  HeaderSection,
  LogoContainer,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="name.svg" width="220px" height="64px" />
          </LogoContainer>
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
